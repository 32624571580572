import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import router_api from '@/common/api/rou.js'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false }) // 取消 NProgress 的右上角螺旋加载提示

import {
    Message
} from 'element-ui'

/**
 * 单页面理由重复跳转 报错 解决
 */
 const originalPush = VueRouter.prototype.push;
 VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err);
 }


Vue.use(VueRouter)

const routes = [{
        //登录
        path: '/login',
        name: 'login',
        component: (resolve) => {
            return require(['@/pages/login/index.vue'], resolve)
        },


    },
    {
        path: '/',
        redirect: '/login'
    },
    //h5页面
    {
        path: '/h5',
        name: 'h5salesnotice',
        component: (resolve) => {
            return require(['@/pages/h5/index.vue'], resolve)
        }
    },
    //h5页面
    {
        path: '/goods',
        name: 'goods',
        component: (resolve) => {
            return require(['@/pages/h5/goods.vue'], resolve)
        }
    },
    //h5页面
    {
        path: '/detail',
        name: 'detail',
        component: (resolve) => {
            return require(['@/pages/h5/detail.vue'], resolve)
        }
    },

    {
        //首页
        path: '/index',
        name: 'index',
        component: (resolve) => {
            return require(['@/pages/index/index.vue'], resolve)
        },

        //用户登录才能访问
        meta: {
            requireAuth: true,
            keepAlive: true
        },
        children: [
            //客户列表
            {
                path: '/customerlist',
                name: 'customerlist',
                component: (resolve) => {
                    return require(['@/pages/customerlist'], resolve)
                },
                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'客户列表'
                    // btns: ["查询","重置","新增","确认","编辑","删除"]
                }
            },
            {
                path: '/moveabout',
                name: 'moveabout',
                component: (resolve) => {
                    return require(['@/pages/moveabout/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true
                }
            },
            {
                //商品列表
                path: '/productlist',
                name: 'productlist',
                component: (resolve) => {
                    return require(['@/pages/productlist/index.vue'], resolve)
                },
                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'商品列表',
                    // btns: ["查询","重置","新增商品","商品导入","更多操作","下架","上架","删除"]
                }
            },
            {
                //销货通知单
                path: '/salesnotice',
                name: 'salesnotice',
                
                component: (resolve) => {
                    return require(['@/pages/salesnotice/index.vue'], resolve)
                },
                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'销货通知单'
                    // btns: ["查询","重置","创建销货通知单","导入销货通知单","导出","批量操作","查看","财务确认","审单确认"," 业务确认","关闭"]
                }
            },
            {
                //销货通知单详情编辑 details
                path: '/salesnotice/DetailsCreatesalesnotice',
                name: 'salesnotice/DetailsCreatesalesnotice',
                component: (resolve) => {
                    return require(['@/pages/salesnotice/DetailsCreatesalesnotice/index.vue'], resolve) 
                }, 

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    title:'销货通知单',
                    XQtogo: 'web-sale-notice-order-detail-data', //查看的权限字符  跳转的时候就判断 session中 是包含这个字段 没有包含就不让跳 并提示
                     //创建的权限字符  跳转的时候就判断 session中 是包含这个字段 没有包含就不让跳 并提示
                }
            },
            {
                //创建销货通知单
                path: '/salesnotice/createsalesnotice_create',
                name: 'salesnotice/createsalesnotice_create',
                component: (resolve) => {
                    return require(['@/pages/salesnotice/createsalesnotice_create/index.vue'], resolve) 
                }, 
                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'销货通知单',
                    CJtogo: 'web-sale-notice-order-create' //创建的权限字符  跳转的时候就判断 session中 是包含这个字段 没有包含就不让跳 并提示
                }
            },
            
            {
                //销货单
                path: '/salese',
                name: 'salese',
                component: (resolve) => {
                    return require(['@/pages/sales/index.vue'], resolve)
                },
                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'销货单'
                }
            },
            {
                //E3
                path: '/salese/SelectE3',
                name: 'saleseSelectE3',
                component: (resolve) => {
                    return require(['@/pages/sales/SelectE3/index.vue'], resolve)
                },
                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    XQtogo: 'web-sale-order-detail',
                    title:'销货单' //查看的权限字符  跳转的时候就判断 session中 是包含这个字段 没有包含就不让跳 并提示
                }
            },
            {
                //退货通知单
                path: '/chargebacknotice',
                name: 'chargebacknotice',
                component: (resolve) => {
                    return require(['@/pages/returnofgoods/chargebacknotice/index.vue'], resolve)
                },
                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'退货通知单'
                }
            },
            {
                //退货通知单
                path: '/chargebacknotice/createChargebacknotice',
                name: 'chargebacknotice',
                component: (resolve) => {
                    return require(['@/pages/returnofgoods/chargebacknotice/createChargebacknotice/index.vue'], resolve)
                },
                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    title:'退货通知单',
                }
            },

            {
                //退货单
                path: '/returnofgoods',
                name: 'returnofgoods',
                component: (resolve) => {
                    return require(['@/pages/returnofgoods/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'退单',

                },

            },
            //创建退单
            {
                path: '/returnofgoods/createback',
                name: 'returnofgoods/createback',
                component: (resolve) => {
                    return require(['@/pages/returnofgoods/createback/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    title:'退单',
                    XQtogo: 'web-back-order-detail-data', //查看的权限字符  跳转的时候就判断 session中 是包含这个字段 没有包含就不让跳 并提示
                    CJtogo: 'web-back-order-create' //创建的权限字符  跳转的时候就判断 session中 是包含这个字段 没有包含就不让跳 并提示
                },
            },


            {
                //E3异常单
                path: '/unusual',
                name: 'unusual',
                component: (resolve) => {
                    return require(['@/pages/unusual/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'E3异常单'
                }
            },

            {
                //收款单
                path: '/makecollections',
                name: 'makecollections',
                component: (resolve) => {
                    return require(['@/pages/makecollections/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'收款单'
                    // btns: ["查询","重置","新增","商品导入","查看","确认","删除","记账","更多操作"]
                },
                children: [

                ]
            },
            {
                //新增收款单
                path: '/makecollections/newmake',
                name: 'makecollections/newmake',
                component: (resolve) => {
                    return require(['@/pages/makecollections/newmake/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    title:'收款单功能',
                    XQtogo: 'web-finance-receipt-detail', //查看的权限字符  跳转的时候就判断 session中 是包含这个字段 没有包含就不让跳 并提示
                    CJtogo: 'web-finance-receipt-create' //创建的权限字符  跳转的时候就判断 session中 是包含这个字段 没有包含就不让跳 并提示
                }
            },
            {
                //调整单
                path: '/adjust',
                name: 'adjust',
                component: (resolve) => {
                    return require(['@/pages/adjust/index.vue'], resolve)
                },
                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'调整单',
                }
            },
            {
                //客户往来流水账
                path: '/runningwater',
                name: 'runningwater',
                component: (resolve) => {
                    return require(['@/pages/runningwater/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'客户往来流水账',
                }
            },
             {
                //客户应收汇总统计
                path: '/colophon',
                name: 'colophon',
                component: (resolve) => {
                    return require(['@/pages/colophon/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'客户应收汇总统计',
                }
            },

            {
                //用户管理
                path: '/user',
                name: 'user',
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'用户管理',
                    // btns: ["重置","分配角色","重置密码","编辑","新增","取消","保存","查询","删除"]
                },
                component: (resolve) => {
                    return require(['@/pages/user/index.vue'], resolve)
                },

            },
            {
                //角色管理
                path: '/role',
                name: 'role',
                // 按钮权限标识  如果存在 则取反才会显示
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'角色管理',
                    // btns: ["重置","分配用户","权限","编辑","新增","取消","保存","查询","删除"]
                },
                component: (resolve) => {
                    return require(['@/pages/role/index.vue'], resolve)
                },



            },
            { //分配用户
                path: '/assignroles',
                name: 'assignroles',
                meta: {
                    requireAuth: true,
                    // btns: ["重置","分配用户","权限","编辑","新增","取消","保存","查询","删除",'取消授权','批量取消授权','添加用户']
                },
                component: (resolve) => {
                    return require(['@/pages/role/assignroles'], resolve)
                },
                meta: {
                    requireAuth: true,
                    title:'分配用户',
                }


            },
            {
                //部门管理
                path: '/department',
                name: 'department',
                component: (resolve) => {
                    return require(['@/pages/department/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'部门管理',
                    // btns: ["重置","权限","编辑","新增","取消","保存","新增","删除"]
                }
            },
            {
                //收款账户
                path: '/collectionaccount',
                name: 'collectionaccount',
                component: (resolve) => {
                    return require(['@/pages/collectionaccount/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'收款账户',
                    // btns: ["查询","重置","编辑","新增","删除"]
                }
            },
            {
                //钉钉审批提醒
                path: '/Nail',
                name: 'Nail',
                component: (resolve) => {
                    return require(['@/pages/Nail/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    title:'钉钉审批提醒',
                }
            },
            {
                //基础设置
                path: '/config',
                name: 'config',
                component: (resolve) => {
                    return require(['@/pages/config/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'基础设置'
                }
            },
            {
                //操作日志
                path: '/operationlog',
                name: 'operationlog',
                component: (resolve) => {
                    return require(['@/pages/operationlog/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'操作日志'
                }
            },
            {
                //导出下载
                path: '/exportdownload',
                name: 'exportdownload',
                component: (resolve) => {
                    return require(['@/pages/exportdownload/index.vue'], resolve)
                },

                //用户登录才能访问
                meta: {
                    requireAuth: true,
                    keepAlive: true,
                    title:'导出下载'
                }
            },
        ]
    },
]

const router = new VueRouter({
    routes,
    mode: 'hash'
})

function  N_nprogress(){
    setTimeout(()=>{    
        NProgress.done() // 调用 NProgress 关闭方法
    },0)
}
/**6.26 前置路由守卫 
 * to:将要进入哪个页面
 * from:从哪个页面进入
 * next:是否可以进入
 */
router.beforeEach((to, from, next) => {
    let istogo = true
    /**浏览器退回操作
     * 1.拿取到即将要去的页面 to
     * 2.获取本地的 Sesstion中的tab 标签页信息 
     * 3.对比 即将去的页面 和 
     */
    NProgress.start() // 调用 NProgress 开始方法
   
    //7.26 获取到本地存储的
    //获取创建按钮点击的iscreate 定义的状态
    //1.由于现在token过期了 弹出提示框点击确定 或者 esc 都可以退出登录.,但是如果用户刷新页面的话 就会卡在页面 不会跳转登录页面 请求也报错 
    // 所以改了 思路,只要弹出弹窗 弹出之前就清空本地的信息
    if (localStorage.getItem('token')) {
        store.commit('getpagebutlist', to.name)
        if(to.name === 'login'){
            next('/index')
        }

      
        //如果是菜单没有权限 .state.pa则需要获取到storegebut,判断是否有内容,如果没有内容就表示次菜单没有权限就不进入到下一步  
        if (store.state.pagebut.length !== 0 || to.name === 'index' || to.name === 'operationlog' || to.name === 'login' || to.name==='exportdownload' || to.name === 'config') {
             //当前路由信息
           
            if (to.meta.XQtogo || to.meta.CJtogo) { 
                    if (store.state.pagebut.indexOf(to.meta.XQtogo) != -1 || store.state.pagebut.indexOf(to.meta.CJtogo) != -1 ) {
                      next()
                    }else{
                        Message({
                            type: 'error',
                            message: '抱歉,您没有此权限'
                        })
                        istogo = false
                    }
            } else {
                next()
            }
        } else {
            if(sessionStorage.getItem('caidanxinxi')){
            if (to.name === 'h5salesnotice' || to.name === 'goods' || to.name === 'detail' ) {
                next()
                //由于进入该页面的用户都是没有登录过得,但是现在还要查询 用户的权限 需要把用户的权限添加到 页面的butpage里面 
                if(to.name === 'h5salesnotice'){
                    let  job_number = 0
                    //如果是跳转h5页面的话 就得获取员工工号查找出对应的权限,如果是在进入页面之后查找的话,第一次进入查找不到权限信息
                    //截取获取 员工代码
                    if(window.location.hash.indexOf('job_number=') !== -1){
                            var user3 = window.location.hash.indexOf('job_number=') +11
                            var user2 = window.location.hash.length;
                            job_number = window.location.hash.substring(user3,user2)
                    }
                    router_api.getuser_id(job_number,to.name) //获取权限
                    //判断store.pagebut 存放按钮权限的 容器不为空的时候进入页面
                    if(store.pagebut !== '' ){
                        setTimeout(() => {
                            next()    
                        }, 1000);
                    }
                }
            }else{
                Message({
                    type: 'error',
                    message: '抱歉,您没有此权限'
                })
                istogo = false
                }
            }else{
                next('/login')
            }
        }
    } else {
        istogo = false
        if (to.name === 'h5salesnotice' || to.name === 'goods' || to.name === 'detail' || to.name === 'login') {
            //由于进入该页面的用户都是没有登录过得,但是现在还要查询 用户的权限 需要把用户的权限添加到 页面的butpage里面
            if(to.name === 'h5salesnotice'){
                let  job_number = 0
                //如果是跳转h5页面的话 就得获取员工工号查找出对应的权限,如果是在进入页面之后查找的话,第一次进入查找不到权限信息
                //截取获取 员工代码
                if(window.location.hash.indexOf('job_number=') !== -1){
                        var user3 = window.location.hash.indexOf('job_number=') +11
                        var user2 = window.location.hash.length
                        job_number = window.location.hash.substring(user3,user2)
                }
                router_api.getuser_id(job_number,to.name) //获取权限
                //判断store.pagebut 存放按钮权限的 容器不为空的时候进入页面
                if(store.pagebut !== '' ){
                    setTimeout(() => {
                        next()    

                    }, 1000);
                }
            }else{
                //不是去h5首页的 其他直接放行
                next()
            }
        }else{
            Message({
                type: 'error',
                message: '请先登录'
            })
            next('/login')
        }
    }

    if(to.name != 'login' && to.name != 'index' && istogo){
        store.commit('getRouterList_Path',to)
    }
    Vue.prototype.$common.getVersion()
    N_nprogress() // 调用 NProgress 关闭方法
})



export default router